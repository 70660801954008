function Fail() {
  // const errCode = queryParams.get('err_code');
    return (
      <div className="App">
           <h1>
  
               Opps! There is something wrong 
  
           </h1>
     </div>
    )
  }
  
  export default  Fail;