// import logo from './logo.svg';
import './App.css';
// import axios from 'axios';
// import { useEffect,useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Success from './component/success';
import Failed from './component/fail';
import PaymentForm from './component/paymentForm'
import Home from './component/main'




function App() {
    
 

  return (
    <Router>

  

    {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Success" element={<Success />} />
          <Route path="/Failed" element={<Failed />} />
          <Route path="/PaymentForm" element={<PaymentForm />} />
        </Routes>
    </Router>
  );
}

export default App;
