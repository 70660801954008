import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

function Main(){

let  [tokens, setTokens] = useState('')

async function getAccessToken() {
  try {
    const BASE_URL = "https://ipg1.apps.net.pk/Ecommerce/api/Transaction/GetAccessToken";

    const merchantId = 21288;
    const securedKey = "pjjfklqXKUP-XHSCC0onJa3qf";

    const response = await axios.post(`${BASE_URL}/token`, {
      MERCHANT_ID : merchantId,
      SECURED_KEY : securedKey,
      BASKET_ID : '',
      TXNAMT: '',
    });

    setTokens(response?.data?.ACCESS_TOKEN)
    console.log(tokens);
 
    console.log(response);
    // alert("Token Fetched Successfully")
    return response;
  } catch (error) {
    console.log(error.message);
    // setRetry(prev => prev + 1); // Increment retry to trigger useEffect
  }
}
 
getAccessToken()

    const queryParamsSubscription = new URLSearchParams(
      {userId: '25',
         paymentAmount: '1', 
        addId:'66a0d260e2513c6813c39b0d',
        businessId : '66a212ce9b199a0486670e2f',
        token:tokens,
        type:'Subscription'

       }).toString();
    // addId is a Subscription id which we user want to pay
  
    const   queryParamsVoucher = new URLSearchParams({ 
      userId: '25',
       paymentAmount: '1', 
       voucherId : '66a2bed8521fc16c8f1c5710',
        type:'Voucher' ,
        token:tokens
      }).toString();
        
    return(
        <div className="App">
    <h1>

      {/* Hello  */}

    </h1>
    Link to the route with parameters
    <Link to={`http://payment.ashhkaro.com/PaymentForm?${queryParamsSubscription}`}> 
    Go to Your Component with Parameters (Subscription)
    </Link>
    <br/>
    <br/>
    <Link to={`http://payment.ashhkaro.com/PaymentForm?${queryParamsVoucher}`}>
    Go to Your Component with Parameters (Voucher)
    </Link>

    </div>
    )
}

export default Main;